// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contracts-templates-tsx": () => import("./../../../src/pages/contracts-templates.tsx" /* webpackChunkName: "component---src-pages-contracts-templates-tsx" */),
  "component---src-pages-contracts-tsx": () => import("./../../../src/pages/contracts.tsx" /* webpackChunkName: "component---src-pages-contracts-tsx" */),
  "component---src-pages-create-contract-tsx": () => import("./../../../src/pages/create-contract.tsx" /* webpackChunkName: "component---src-pages-create-contract-tsx" */),
  "component---src-pages-generated-contract-tsx": () => import("./../../../src/pages/generated-contract.tsx" /* webpackChunkName: "component---src-pages-generated-contract-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-vendors-tsx": () => import("./../../../src/pages/vendors.tsx" /* webpackChunkName: "component---src-pages-vendors-tsx" */),
  "component---src-pages-welcome-tsx": () => import("./../../../src/pages/welcome.tsx" /* webpackChunkName: "component---src-pages-welcome-tsx" */)
}

